import axios, { AxiosInstance } from 'axios'
import store from '../store/index'
import { v4 as uuidv4 } from 'uuid'
import { emitter } from '../events/eventbus'
import { msal } from '../../authConfig'

const fetchConfig = async () => {
  const result = await fetch('/appsettings.json')

  return await result.json()
}

function getAccessToken() {
  const accessToken = sessionStorage.getItem('adal.idtoken')

  return accessToken
}

const cockpitClient: AxiosInstance = axios.create({
  headers: {
    'Content-type': 'application/json'
  }
})

const fetchTokenAsync = async () => {
  const { msalInstance, msalConfig } = await msal()
  const currentAccounts = msalInstance.getAllAccounts()
  if (currentAccounts.length === 0) {
    return null
  } else if (currentAccounts.length === 1) {
    const account = currentAccounts[0]
    store.commit('SET_USER_DATA', { account })
    try {
      const response = await msalInstance.acquireTokenSilent({
        scopes: [msalConfig.auth.clientId + '/.default'],
        account: currentAccounts[0]
      })

      return response.accessToken
    } catch (e) {
      return null
    }
  }

  return null
}

cockpitClient.interceptors.request.use(
  async (config: any) => {
    const cfg = await fetchConfig()
    const token = await fetchTokenAsync()
    config.baseURL = cfg.baseURL
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
      'correlation-id': uuidv4()
    }

    return config
  },
  async (error: any) => {
    Promise.reject(error)
  }
)

cockpitClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error == null ||
      error.response == null ||
      [500, 503].includes(error.response.status)
    ) {
      emitter.emit('errorEvent', 'serverError')
      throw error
    }

    if (error.response.data == 'Unauthorized') {
      emitter.emit('errorEvent', 'unauthorisedError')
    }

    const request = error.config

    if (error.response.status === 401 && !request._retry) {
      request._retry = true
      await store.dispatch('SignIn')

      return cockpitClient(request)
    }
    throw error
  }
)
export default cockpitClient
